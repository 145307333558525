import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_ENDPOINTS } from '../config';
import '../styles/App.css';
import '../styles/Login.css';

function Login({ setUser }) {
  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Initialize the matrix animation
    const canvas = document.getElementById('matrix-canvas');
    if (canvas) {
      const ctx = canvas.getContext('2d');
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;

      const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      const fontSize = 14;
      const columns = canvas.width / fontSize;
      const drops = Array(Math.floor(columns)).fill(1);

      function drawMatrix() {
        ctx.fillStyle = 'rgba(0, 0, 0, 0.05)';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.fillStyle = '#0F0';
        ctx.font = `${fontSize}px monospace`;

        for (let i = 0; i < drops.length; i++) {
          const text = chars[Math.floor(Math.random() * chars.length)];
          ctx.fillText(text, i * fontSize, drops[i] * fontSize);
          if (drops[i] * fontSize > canvas.height && Math.random() > 0.975) {
            drops[i] = 0;
          }
          drops[i]++;
        }
      }

      const matrixInterval = setInterval(drawMatrix, 50);
      return () => clearInterval(matrixInterval);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    
    if (!name.trim() || !company.trim()) {
      setError('Please enter both name and company');
      return;
    }

    try {
      const response = await fetch(API_ENDPOINTS.login, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          name: name.trim(), 
          company: company.trim() 
        }),
      });

      const userData = await response.json();
      
      if (!response.ok || userData.status === 'error') {
        throw new Error(userData.message || 'Login failed');
      }

      if (!userData.id) {
        throw new Error('Invalid user data received');
      }

      const user = {
        id: userData.id,
        name: userData.name,
        company: userData.company
      };
      
      setUser(user);
      navigate('/home');
    } catch (error) {
      console.error('Login error:', error);
      setError(error.message || 'Failed to log in. Please try again.');
    }
  };

  return (
    <div className="login-page">
      <canvas id="matrix-canvas" className="matrix-background"></canvas>
      <div className="login-container">
        <div className="cyber-glow">
          <h1 className="title">CYBERSTRIKERZ</h1>
          <div className="glitch-line"></div>
        </div>
        <h2 className="subtitle">Cybersecurity Training Platform</h2>
        {error && <div className="error-message">{error}</div>}
        <form onSubmit={handleSubmit} className="cyber-form">
          <div className="input-group">
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter your name"
              className="cyber-input"
              required
            />
            <div className="input-line"></div>
          </div>
          <div className="input-group">
            <input
              type="text"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              placeholder="Enter your company"
              className="cyber-input"
              required
            />
            <div className="input-line"></div>
          </div>
          <button type="submit" className="cyber-button">
            <span className="button-text">Start Training</span>
            <div className="button-glitch"></div>
          </button>
        </form>
      </div>
    </div>
  );
}

export default Login;
