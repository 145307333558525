import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/App.css';
import '../styles/HomePage.css';

const industries = [
  {
    id: 'hospitality',
    name: 'Hospitality',
    description: 'Learn to protect guest data and secure hospitality systems.',
    icon: '🏨',
    lessonCount: 6
  },
  {
    id: 'logistics',
    name: 'Logistics',
    description: 'Supply chain cybersecurity and logistics system protection.',
    icon: '🚛',
    lessonCount: 4
  },
  {
    id: 'medical',
    name: 'Medical',
    description: 'HIPAA compliance and healthcare data protection training.',
    icon: '🏥',
    lessonCount: 6
  },
  {
    id: 'military',
    name: 'Military',
    description: 'Specialized training for military cybersecurity protocols and classified information protection.',
    icon: '🎖️',
    lessonCount: 4
  },
  {
    id: 'real-estate',
    name: 'Real Estate',
    description: 'Cybersecurity training focused on protecting sensitive property and client information.',
    icon: '🏢',
    lessonCount: 5
  }
];

function HomePage({ user }) {
  const navigate = useNavigate();

  const handleIndustryClick = (id) => {
    navigate(`/industry/${id}`);
  };

  return (
    <div className="home-page">
      <h1 className="main-title">WELCOME TO CYBERSTRIKERZ</h1>
      <h3 className="select-training" style={{ color: 'white', marginBottom: '2rem' }}>Select Your Industry Training</h3>
      
      <div className="industries-grid">
        {industries.map((industry) => (
          <div
            key={industry.id}
            className="industry-card"
            onClick={() => handleIndustryClick(industry.id)}
          >
            <div className="industry-icon">{industry.icon}</div>
            <h3 className="industry-name">{industry.name}</h3>
            <p className="industry-description">{industry.description}</p>
            <div className="lessons-count">{industry.lessonCount} LESSONS</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default HomePage;
