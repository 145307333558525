import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { API_ENDPOINTS } from '../config';
import '../styles/App.css';

const industries = {
  'military': {
    title: 'MILITARY CYBERSECURITY TRAINING',
    description: 'Specialized training for military cybersecurity protocols and classified information protection.',
    icon: '🎖️',
    totalLessons: 4
  },
'real-estate': {
    title: 'REAL ESTATE CYBERSECURITY TRAINING',
    description: (
        <div className="industry-description">
            <h3>Module Overview</h3>
            <div className="objective">
                <h4>Objective</h4>
                <p>Equip real estate agents, transaction coordinators, and administrative staff with essential cybersecurity knowledge to:</p>
                <ul>
                    <li>Protect client data</li>
                    <li>Prevent fraud</li>
                    <li>Safely manage digital tools and transactions</li>
                </ul>
            </div>
            <div className="target-audience">
                <h4>Target Audience</h4>
                <p>Agents, administrative staff, and transaction coordinators with role-specific learning tracks.</p>
            </div>
        </div>
    ),
    icon: '🏢',
    totalLessons: 5
},
  'hospitality': {
    title: 'HOSPITALITY CYBERSECURITY TRAINING',
    description: (
        <div className="industry-description">
            <h3>Hospitality Cybersecurity Training Module</h3>
            <div className="training-features">
                <ul>
                    <li>Tailor the training to the specific needs of your organization</li>
                    <li>Make training engaging and interactive</li>
                    <li>Encourage a culture of security awareness</li>
                </ul>
            </div>
            <div className="training-impact">
                <p>By implementing cybersecurity best practices and the provided training, hospitality organizations can significantly reduce their risk of cyberattacks and protect their valuable assets.</p>
            </div>
        </div>
    ),
    icon: '🏨',
    totalLessons: 5
},
  'medical': {
    title: 'MEDICAL CYBERSECURITY TRAINING',
    description: 'HIPAA compliance and healthcare data protection training.',
    icon: '🏥',
    totalLessons: 6
  },
  'logistics': {
    title: 'LOGISTICS CYBERSECURITY TRAINING',
    description: (
        <div className="industry-description">
            <h3>Logistics Cybersecurity Training Module</h3>
            <div className="module-overview">
                <h3>Module Overview</h3>
                <div className="objective">
                    <h4>Objective</h4>
                    <p>Equip logistics coordinators, supply chain professionals, and administrative staff with essential cybersecurity knowledge to:</p>
                    <ul>
                        <li>Protect sensitive supply chain data</li>
                        <li>Prevent fraud and unauthorized access</li>
                        <li>Safely manage digital tools and logistics operations</li>
                    </ul>
                </div>
                <div className="target-audience">
                    <h4>Target Audience</h4>
                    <p>Logistics staff, administrative personnel, and supply chain managers with role-specific learning tracks.</p>
                </div>
            </div>
        </div>
    ),
    icon: '🚛',
    totalLessons: 5
}
};

function IndustryPage({ user }) {
  const { industry } = useParams();
  const navigate = useNavigate();
  const industryData = industries[industry];
  const [progress, setProgress] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProgress = async () => {
      try {
        if (!user || !user.id) {
          throw new Error('User not authenticated');
        }
        
        const response = await fetch(API_ENDPOINTS.getProgress(user.id, industry));
        const data = await response.json();
        
        if (!response.ok) {
          throw new Error(data.error || 'Failed to fetch progress');
        }
        
        // Generate lessons array based on total lessons
        const totalLessons = industryData?.totalLessons || 0;
        const updatedLessons = Array.from({ length: totalLessons }, (_, i) => {
          const lessonNum = i + 1;
          const lessonProgress = data.find(p => p.lesson_number === lessonNum);
          const previousLessonCompleted = i === 0 || data.find(p => p.lesson_number === lessonNum - 1 && p.completed);
          
          return {
            id: lessonNum,
            title: `Lesson ${lessonNum}`,
            locked: !previousLessonCompleted,
            completed: lessonProgress?.completed || false
          };
        });
        
        setProgress(data);
        setLessons(updatedLessons);
        setError(null);
      } catch (error) {
        console.error('Error fetching progress:', error);
        setError(error.message);
        
        // Still set lessons even if progress fetch fails
        const totalLessons = industryData?.totalLessons || 0;
        const defaultLessons = Array.from({ length: totalLessons }, (_, i) => ({
          id: i + 1,
          title: `Lesson ${i + 1}`,
          locked: i !== 0, // Only first lesson is unlocked
          completed: false
        }));
        setLessons(defaultLessons);
      } finally {
        setLoading(false);
      }
    };

    fetchProgress();
  }, [industry, user, industryData]);

  const renderLessons = () => {
    return lessons.map((lesson) => (
      <div
        key={lesson.id}
        className={`lesson-item ${lesson.locked ? 'locked' : ''} ${lesson.completed ? 'completed' : ''}`}
        onClick={() => {
          if (!lesson.locked) {
            navigate(`/lesson/${industry}/${lesson.id}`);
          }
        }}
      >
        <span className="lesson-title">Lesson {lesson.id}</span>
        <span className="lesson-status">
          {lesson.completed ? '✅ Completed' : 
           lesson.locked ? '🔒 Locked' : 
           '📝 Start Lesson'}
        </span>
      </div>
    ));
  };

  if (!industryData) {
    return <div>Industry not found</div>;
  }

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="industry-page">
      <div className="industry-icon">{industryData.icon}</div>
      <h1 className="industry-title">{industryData.title}</h1>
      <p className="industry-description">{industryData.description}</p>
      
      <div className="lessons-list">
        {renderLessons()}
      </div>
    </div>
  );
}

export default IndustryPage;
