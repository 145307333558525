import React, { useState } from 'react';
import { 
  BrowserRouter, 
  Routes, 
  Route, 
  Navigate, 
  Link, 
  useLocation,
  UNSAFE_DataRouterContext as DataRouterContext,
  UNSAFE_DataRouterStateContext as DataRouterStateContext
} from 'react-router-dom';
import Login from './components/Login';
import HomePage from './components/HomePage';
import IndustryPage from './components/IndustryPage';
import LessonPage from './components/LessonPage';
import ProfilePage from './components/ProfilePage';
import './styles/App.css';

// Configure React Router future flags
const router = {
  future: {
    v7_startTransition: true,
    v7_relativeSplatPath: true
  }
};

function NavigationBar() {
  const location = useLocation();
  const paths = location.pathname.split('/').filter(Boolean);
  
  return (
    <nav className="nav-breadcrumb">
      <Link to="/home">Home</Link>
      {paths.length > 1 && paths[0] !== 'login' && (
        <>
          <span className="nav-separator">›</span>
          <Link to={`/industry/${paths[1]}`}>{paths[1]}</Link>
        </>
      )}
      {paths.length > 2 && (
        <>
          <span className="nav-separator">›</span>
          <span>Lesson {paths[2]}</span>
        </>
      )}
    </nav>
  );
}

function App() {
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem('user');
    try {
      const parsedUser = savedUser ? JSON.parse(savedUser) : null;
      return parsedUser && parsedUser.id ? parsedUser : null;
    } catch {
      localStorage.removeItem('user');
      return null;
    }
  });

  const handleSetUser = (userData) => {
    if (userData && userData.id) {
      localStorage.setItem('user', JSON.stringify(userData));
      setUser(userData);
    } else {
      localStorage.removeItem('user');
      setUser(null);
    }
  };

  const handleLogout = () => {
    handleSetUser(null);
    window.location.href = '/login';
  };

  return (
    <BrowserRouter {...router}>
      <div className="App">
        {user && (
          <div className="user-info">
            <span>Welcome, {user.name}</span>
            <Link to="/profile" className="profile-btn">Profile</Link>
            <button onClick={handleLogout}>Logout</button>
          </div>
        )}
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={
            user ? <Navigate to="/home" /> : <Login setUser={handleSetUser} />
          } />
          <Route 
            path="/home" 
            element={
              user ? (
                <>
                  <NavigationBar />
                  <HomePage user={user} />
                </>
              ) : (
                <Navigate to="/login" />
              )
            } 
          />
          <Route 
            path="/industry/:industry" 
            element={
              user ? (
                <>
                  <NavigationBar />
                  <IndustryPage user={user} />
                </>
              ) : (
                <Navigate to="/login" />
              )
            } 
          />
          <Route 
            path="/lesson/:industry/:lessonId" 
            element={
              user ? (
                <>
                  <NavigationBar />
                  <LessonPage user={user} />
                </>
              ) : (
                <Navigate to="/login" />
              )
            } 
          />
          <Route 
            path="/profile" 
            element={
              user ? (
                <>
                  <NavigationBar />
                  <ProfilePage user={user} onLogout={handleLogout} />
                </>
              ) : (
                <Navigate to="/login" />
              )
            } 
          />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
