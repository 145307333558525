import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { API_ENDPOINTS, API_BASE_URL } from '../config';
import '../styles/LessonPage.css';

function LessonPage({ user }) {
  const { industry, lessonId } = useParams();
  const navigate = useNavigate();
  const [lesson, setLesson] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [showResults, setShowResults] = useState(false);
  const [quizStarted, setQuizStarted] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [responseTimes, setResponseTimes] = useState([]); // Track time per question
  const [questionStartTime, setQuestionStartTime] = useState(null);
  const [quizResults, setQuizResults] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [isLastLesson, setIsLastLesson] = useState(false);

  useEffect(() => {
    const fetchLesson = async () => {
      if (!user?.id || !industry || !lessonId) {
        setError('Missing required information');
        navigate('/login');
        return;
      }

      try {
        setLoading(true);
        setError(null);

        // First fetch the lesson content
        const lessonResponse = await fetch(API_ENDPOINTS.getLesson(industry, lessonId));
        if (!lessonResponse.ok) {
          const errorData = await lessonResponse.json();
          throw new Error(errorData.error || 'Failed to fetch lesson content');
        }

        const lessonData = await lessonResponse.json();
        if (!lessonData || !Array.isArray(lessonData.pages) || lessonData.pages.length === 0) {
          throw new Error('Invalid lesson data: missing or empty pages');
        }

        // Get total lessons for this industry
        const industryResponse = await fetch(API_ENDPOINTS.getIndustryInfo(industry));
        if (!industryResponse.ok) {
          throw new Error('Failed to fetch industry information');
        }
        const industryInfo = await industryResponse.json();
        if (!industryInfo || typeof industryInfo.totalLessons !== 'number') {
          throw new Error('Invalid industry information received');
        }
        
        // Check if this is the last lesson based on industry total lessons
        setIsLastLesson(parseInt(lessonId, 10) >= industryInfo.totalLessons);

        // Ensure the content is properly formatted
        const formattedPages = lessonData.pages.map(page => ({
          ...page,
          content: typeof page.content === 'string' ? page.content : JSON.stringify(page.content)
        }));

        setLesson({
          ...lessonData,
          pages: formattedPages
        });

        // Then start the lesson
        const startResponse = await fetch(API_ENDPOINTS.startLesson, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user_id: user.id,
            industry,
            lesson: parseInt(lessonId, 10)
          }),
        });

        if (!startResponse.ok) {
          const startData = await startResponse.json();
          // Don't throw error if lesson is already completed
          if (!startData.message?.includes('already completed')) {
            throw new Error(startData.error || 'Failed to start lesson');
          }
        }

        setCurrentPage(1);
        setQuizStarted(false);
        setAnswers([]);
        
      } catch (error) {
        console.error('Error:', error);
        setError(error.message);
        Swal.fire({
          title: 'Error',
          text: error.message || 'Failed to load lesson. Please try again.',
          icon: 'error',
          confirmButtonText: 'Return to Industry Page',
          customClass: {
            container: 'cyberpunk-alert',
            popup: 'cyberpunk-popup',
            title: 'cyberpunk-title',
            confirmButton: 'cyberpunk-button-primary'
          }
        }).then(() => {
          navigate(`/industry/${industry}`);
        });
      } finally {
        setLoading(false);
      }
    };

    fetchLesson();
  }, [industry, lessonId, navigate, user]);

  // Load saved quiz state on component mount
  useEffect(() => {
    const savedState = localStorage.getItem(`quiz_state_${user?.id}_${industry}_${lessonId}`);
    if (savedState) {
      const { quizStarted, currentQuestion, answers, responseTimes } = JSON.parse(savedState);
      setQuizStarted(quizStarted);
      setCurrentQuestion(currentQuestion);
      setAnswers(answers);
      setResponseTimes(responseTimes);
      if (quizStarted) {
        setQuestionStartTime(Date.now());
      }
    }
  }, [user?.id, industry, lessonId]);

  // Save quiz state on changes
  useEffect(() => {
    if (user?.id && quizStarted) {
      localStorage.setItem(`quiz_state_${user?.id}_${industry}_${lessonId}`, JSON.stringify({
        quizStarted,
        currentQuestion,
        answers,
        responseTimes
      }));
    }
  }, [quizStarted, currentQuestion, answers, responseTimes, user?.id, industry, lessonId]);

  // Enhanced scroll to top function with options
  const scrollToTop = (delay = 0) => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
        duration: 500
      });
    }, delay);
  };

  // Add effect to handle scroll on page changes
  useEffect(() => {
    if (!loading && lesson) {
      scrollToTop(100); // Small delay to ensure content is rendered
    }
  }, [currentPage, quizStarted, loading, lesson]);

  const startQuiz = () => {
    if (!lesson?.quiz) return;
    
    setQuizStarted(true);
    setCurrentQuestion(0);
    // Initialize answers array with null values for all questions
    setAnswers(new Array(lesson.quiz.length).fill(null));
    setResponseTimes(new Array(lesson.quiz.length).fill(0));
    setQuestionStartTime(Date.now());
  };

  const handleNextPage = () => {
    if (!lesson?.pages) return;
    
    if (currentPage < lesson.pages.length) {
      setCurrentPage(currentPage + 1);
    } else if (!quizStarted && lesson.quiz?.length > 0) {
      startQuiz();
    } else {
      navigate(`/industry/${industry}`);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleAnswerClick = async (answerIndex) => {
    if (submitting) return;
    setSubmitting(true);

    // Create a new answers array with the current answer
    const newAnswers = [...answers];
    newAnswers[currentQuestion] = Number(answerIndex);
    setAnswers(newAnswers);

    // Calculate response time
    const responseTime = (Date.now() - questionStartTime) / 1000;
    const newResponseTimes = [...responseTimes];
    newResponseTimes[currentQuestion] = responseTime;
    setResponseTimes(newResponseTimes);

    // Move to next question or submit quiz
    if (currentQuestion + 1 === lesson.quiz.length) {
      await handleQuizSubmit(newAnswers);
    } else {
      setCurrentQuestion(currentQuestion + 1);
      setQuestionStartTime(Date.now());
      scrollToTop(100); // Add small delay for smooth transition
    }
    setSubmitting(false);
  };

  const handleQuizSubmit = async (finalAnswers) => {
    try {
      const answersToSubmit = finalAnswers || answers;
      
      const response = await fetch(API_ENDPOINTS.submitQuiz, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: user.id,
          industry,
          lesson_number: parseInt(lessonId, 10),
          answers: answersToSubmit.map(a => Number(a)),
          response_times: responseTimes
        }),
      });

      const responseData = await response.json();
      
      if (!response.ok) {
        throw new Error(responseData.error || 'Failed to submit quiz');
      }

      // Store only necessary quiz results data
      setQuizResults({
        ...responseData,
        roundedScore: Math.round(responseData.score),
        answers: answersToSubmit.map(a => Number(a))
      });

      const roundedScore = Math.round(responseData.score);
      const passed = roundedScore >= 67;

      // If passed, mark lesson as completed
      if (passed) {
        try {
          const completionResponse = await fetch(API_ENDPOINTS.completeLesson, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              user_id: user.id,
              industry,
              lesson_number: parseInt(lessonId, 10),
              score: roundedScore
            }),
          });

          if (!completionResponse.ok) {
            const completionData = await completionResponse.json();
            throw new Error(completionData.error || 'Failed to mark lesson as completed');
          }
        } catch (error) {
          console.error('Error marking lesson as completed:', error);
        }
      }

      const result = await Swal.fire({
        title: passed ? 'GREAT JOB!' : 'UNFORTUNATELY!',
        text: passed 
          ? `You passed the quiz with ${roundedScore}%!` 
          : `You didn't pass. You need 67% to pass and you only got ${roundedScore}%`,
        icon: passed ? 'success' : 'warning',
        showCancelButton: false,
        confirmButtonText: 'Review Results',
        customClass: {
          container: 'cyberpunk-alert',
          popup: 'cyberpunk-popup',
          title: 'cyberpunk-title',
          confirmButton: 'cyberpunk-button-primary'
        }
      });

      if (result.isConfirmed) {
        setShowResults(true);
        setQuizStarted(false);
      }

    } catch (error) {
      console.error('Error submitting quiz:', error);
      Swal.fire({
        title: 'Error',
        text: error.message || 'Failed to submit quiz. Please try again.',
        icon: 'error',
        confirmButtonText: 'OK',
        customClass: {
          container: 'cyberpunk-alert',
          popup: 'cyberpunk-popup',
          title: 'cyberpunk-title',
          confirmButton: 'cyberpunk-button-primary'
        }
      });
    }
  };

  const ResultsReview = () => {
    const passed = quizResults.roundedScore >= 67;

    return (
      <div className="quiz-review-container">
        <h2>QUIZ RESULTS REVIEW</h2>
        <div className="quiz-questions-review">
          {lesson.quiz.map((question, index) => {
            const selectedAnswer = Number(quizResults.answers[index]);
            const correctAnswer = Number(question.correct_answer);
            const isCorrect = selectedAnswer === correctAnswer;

            return (
              <div key={index} className={`question-review ${isCorrect ? 'correct' : 'incorrect'}`}>
                <h3>Question {index + 1}</h3>
                <p>{question.question}</p>
                <div className="answers-review">
                  {question.options.map((option, optIndex) => {
                    const isSelected = Number(optIndex) === selectedAnswer;
                    const isCorrectAnswer = Number(optIndex) === correctAnswer;
                    
                    let className = 'answer-option';
                    if (isCorrectAnswer) className += ' correct-answer';
                    if (isSelected && !isCorrectAnswer) className += ' selected-answer';
                    
                    return (
                      <div key={optIndex} className={className}>
                        {option}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
        <div className="review-actions">
          {passed ? (
            <>
              <button 
                className="cyberpunk-button-primary"
                onClick={() => navigate(`/industry/${industry}`)}
              >
                Return Home
              </button>
              {isLastLesson ? (
                <button 
                  className="cyberpunk-button-secondary"
                  onClick={async () => {
                    try {
                      // First generate the certificate
                      const generateResponse = await fetch(API_ENDPOINTS.generateCertificate, {
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                          user_id: user.id,
                          industry,
                          completion_date: new Date().toISOString()
                        }),
                      });

                      if (!generateResponse.ok) {
                        throw new Error('Failed to generate certificate');
                      }

                      const responseData = await generateResponse.json();
                      
                      if (responseData.certificate_url) {
                        // Remove any leading /api prefix and construct the full URL
                        const certificateUrl = responseData.certificate_url.replace(/^\/api\//, '');
                        window.open(`${API_BASE_URL}/${certificateUrl}`, '_blank');
                      } else {
                        throw new Error('Invalid certificate URL');
                      }
                    } catch (error) {
                      console.error('Error generating certificate:', error);
                      Swal.fire({
                        title: 'Error',
                        text: 'Failed to generate certificate. Please try again.',
                        icon: 'error',
                        confirmButtonText: 'OK'
                      });
                    }
                  }}
                >
                  View Certificate
                </button>
              ) : (
                <button 
                  className="cyberpunk-button-secondary"
                  onClick={() => {
                    // Reset quiz state before navigating
                    setQuizStarted(false);
                    setShowResults(false);
                    setCurrentQuestion(0);
                    setAnswers([]);
                    setResponseTimes([]);
                    setQuizResults(null);
                    setCurrentPage(1);
                    // Navigate to next lesson
                    navigate(`/lesson/${industry}/${parseInt(lessonId, 10) + 1}`);
                  }}
                >
                  Continue to Next Lesson
                </button>
              )}
            </>
          ) : (
            <>
              <button 
                className="cyberpunk-button-primary"
                onClick={startQuiz}
              >
                Try Again
              </button>
              <button 
                className="cyberpunk-button-secondary"
                onClick={() => {
                  setQuizStarted(false);
                  setShowResults(false);
                  setCurrentQuestion(0);
                  setAnswers([]);
                  setResponseTimes([]);
                  setQuizResults(null);
                  setCurrentPage(1);
                }}
              >
                Review Lesson
              </button>
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="lesson-page">
      {loading ? (
        <div className="loading">Loading lesson content...</div>
      ) : error ? (
        <div className="error">
          <h3>Error Loading Lesson</h3>
          <p>{error}</p>
          <button onClick={() => navigate(`/industry/${industry}`)}>
            Return to Industry Page
          </button>
        </div>
      ) : !lesson ? (
        <div className="error">
          <h3>Lesson Not Found</h3>
          <p>The requested lesson could not be found.</p>
          <button onClick={() => navigate(`/industry/${industry}`)}>
            Return to Industry Page
          </button>
        </div>
      ) : quizStarted ? (
        <div className="quiz-container">
          <div className="quiz-header">
            <h2>Quiz Time!</h2>
            <div className="quiz-progress">
              <div 
                className="quiz-progress-bar" 
                style={{ width: `${((currentQuestion + 1) / lesson.quiz.length) * 100}%` }}
              ></div>
              <span className="quiz-progress-text">
                Question {currentQuestion + 1} of {lesson.quiz.length}
              </span>
            </div>
          </div>
          <div className="quiz-question">
            <h3>Question {currentQuestion + 1}</h3>
            <p>{lesson.quiz[currentQuestion].question}</p>
            <div className="quiz-options">
              {lesson.quiz[currentQuestion].options.map((option, index) => (
                <button
                  key={index}
                  onClick={() => handleAnswerClick(index)}
                  className="quiz-option-button"
                >
                  {option}
                </button>
              ))}
            </div>
          </div>
        </div>
      ) : showResults ? (
        <ResultsReview />
      ) : (
        <div className="lesson-content">
          <div className="lesson-header">
            <h2>{lesson.title || `Lesson ${lessonId}`}</h2>
            <div className="lesson-progress">
              <div 
                className="lesson-progress-bar" 
                style={{ width: `${(currentPage / lesson.pages.length) * 100}%` }}
              ></div>
              <span className="lesson-progress-text">
                {Math.round((currentPage / lesson.pages.length) * 100)}% Complete
              </span>
            </div>
          </div>
          <div className="lesson-page-content">
            {lesson.pages && lesson.pages[currentPage - 1]?.content ? (
              <div dangerouslySetInnerHTML={{ __html: lesson.pages[currentPage - 1].content }} />
            ) : (
              <div className="error">No content available for this page.</div>
            )}
          </div>
          <div className="lesson-navigation">
            <button
              onClick={handlePrevPage}
              disabled={currentPage === 1}
              className="nav-button"
            >
              Previous
            </button>
            <span className="page-indicator">
              Page {currentPage} of {lesson.pages.length}
            </span>
            <button onClick={handleNextPage} className="nav-button">
              {currentPage < lesson.pages.length ? 'Next' : lesson.quiz?.length > 0 ? 'Start Quiz' : 'Finish Lesson'}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default LessonPage;
