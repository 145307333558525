import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { API_ENDPOINTS } from '../config';
import '../styles/ProfilePage.css';

function ProfilePage({ user, onLogout }) {
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchProfileData();
  }, [user.id]);

  const fetchProfileData = async () => {
    try {
      const response = await fetch(`/api/user/profile/${user.id}`);
      if (!response.ok) throw new Error('Failed to fetch profile data');
      const data = await response.json();
      setProfileData(data);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const handleResetProgress = async () => {
    try {
      const result = await Swal.fire({
        title: 'Reset Progress',
        text: 'Are you sure you want to reset all your progress? This action cannot be undone.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, reset my progress',
        cancelButtonText: 'No, keep my progress',
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6'
      });

      if (result.isConfirmed) {
        const response = await fetch(`/api/user/reset-progress/${user.id}`, {
          method: 'POST'
        });
        
        if (!response.ok) {
          throw new Error('Failed to reset progress');
        }

        await Swal.fire({
          title: 'Success!',
          text: 'Your progress has been reset successfully.',
          icon: 'success',
          confirmButtonColor: '#28a745'
        });

        await fetchProfileData(); // Refresh the data
      }
    } catch (err) {
      await Swal.fire({
        title: 'Error!',
        text: err.message,
        icon: 'error',
        confirmButtonColor: '#d33'
      });
      setError(err.message);
    }
  };

  const handleDeleteAccount = async () => {
    try {
      const result = await Swal.fire({
        title: 'Delete Account',
        text: 'Are you sure you want to delete your account? This action cannot be undone.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete my account',
        cancelButtonText: 'No, keep my account',
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6'
      });

      if (result.isConfirmed) {
        const response = await fetch(`/api/user/delete-account/${user.id}`, {
          method: 'DELETE',
          credentials: 'include'
        });

        if (!response.ok) {
          throw new Error('Failed to delete account');
        }

        // Clear all client-side data
        localStorage.clear();
        sessionStorage.clear();
        document.cookie.split(";").forEach(c => {
          document.cookie = c.trim().split("=")[0] + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
        });

        await Swal.fire({
          title: 'Success!',
          text: 'Your account has been deleted successfully.',
          icon: 'success',
          confirmButtonColor: '#28a745'
        });

        // Call onLogout to handle state cleanup
        if (onLogout) {
          onLogout();
        }
        
        // Force navigation to login page
        window.location.href = '/login';
      }
    } catch (err) {
      await Swal.fire({
        title: 'Error!',
        text: err.message,
        icon: 'error',
        confirmButtonColor: '#d33'
      });
      setError(err.message);
    }
  };

  if (loading) return <div className="loading">Loading profile data...</div>;
  if (error) return <div className="error">Error: {error}</div>;
  if (!profileData) return null;

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString();
  };

  return (
    <div className="profile-container">
      <h1>User Profile</h1>
      
      <section className="profile-section user-info">
        <h2>Account Information</h2>
        <div className="info-grid">
          <div className="info-item">
            <label>Name:</label>
            <span>{profileData.user.name}</span>
          </div>
          <div className="info-item">
            <label>Company:</label>
            <span>{profileData.user.company}</span>
          </div>
          <div className="info-item">
            <label>Member Since:</label>
            <span>{formatDate(profileData.user.created_at)}</span>
          </div>
        </div>
      </section>

      <section className="profile-section progress-overview">
        <h2>Training Progress</h2>
        <div className="progress-grid">
          {Object.entries(profileData.progress).map(([industry, progress]) => (
            <div key={industry} className="progress-card">
              <h3>{industry.replace('_', ' ').toUpperCase()}</h3>
              <div className="progress-bar">
                <div 
                  className="progress-fill"
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
              <span className="progress-text">{progress}% Complete</span>
            </div>
          ))}
        </div>
      </section>

      <section className="profile-section certificates">
        <h2>Earned Certificates</h2>
        <div className="certificates-grid">
          {profileData.certificates.map(cert => (
            <div key={cert.id} className="certificate-card">
              <h3>{cert.industry.replace('_', ' ').toUpperCase()}</h3>
              <p>Completed: {formatDate(cert.completion_date)}</p>
              <a 
                href={API_ENDPOINTS.getCertificate(cert.certificate_url)}
                target="_blank" 
                rel="noopener noreferrer"
                className="view-certificate-btn"
              >
                View Certificate
              </a>
            </div>
          ))}
          {profileData.certificates.length === 0 && (
            <p className="no-certificates">No certificates earned yet.</p>
          )}
        </div>
      </section>

      <section className="profile-section account-management">
        <h2>Account Management</h2>
        <div className="button-group">
          <button 
            className="reset-progress-btn"
            onClick={handleResetProgress}
          >
            Reset Progress
          </button>
          <button 
            className="delete-account-btn"
            onClick={handleDeleteAccount}
          >
            Delete Account
          </button>
        </div>
      </section>
    </div>
  );
}

export default ProfilePage;
