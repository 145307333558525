// API Configuration
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '/api';  // Use relative path for all environments

// Ensure all API requests use this base URL
export const API_ENDPOINTS = {
    login: `${API_BASE_URL}/login`,
    startLesson: `${API_BASE_URL}/start_lesson`,
    getLesson: (industry, lessonId) => `${API_BASE_URL}/lesson/${industry}/${lessonId}`,
    getProgress: (userId, industry) => `${API_BASE_URL}/industry_progress/${userId}/${industry}`,
    submitQuiz: `${API_BASE_URL}/submit_quiz`,
    completeLesson: `${API_BASE_URL}/complete_lesson`,
    generateCertificate: `${API_BASE_URL}/generate_certificate`,
    getCertificate: (certificateUrl) => certificateUrl.startsWith('/') ? certificateUrl : `${API_BASE_URL}/${certificateUrl}`,
    getIndustryInfo: (industry) => `${API_BASE_URL}/industry/${industry}/info`
};
